import React, {useContext} from 'react';
import {I18nContext, T} from '@deity-io/falcon-i18n';
import {Link} from 'react-router-dom';
import DesktopResponsive from "./DesktopResponsive";

import './NewsletterMobileBanner.scss';

const NewsletterMobileBanner = () => {
    const {t} = useContext(I18nContext);

    if (!DesktopResponsive().isDesktop) {
        return (
            <div className="NewsletterMobileBannerContent">
                <div>
                    <div className="bannerImageMobile"></div>
                    <p className="newsletterSignUpBoldMobile">
                        <T id="newsletterBanner.newsletterSignUpBoldMobile"/>
                    </p>
                    <p className="newsletterSignUpSmallMobile">
                        <T id="newsletterBanner.newsletterSignUpSmallMobile"/>
                    </p>
                    <p className="newsletterSignUpButtonMobile">
                        <Link to={`/${t('newsletterSlider.newsletterLink')}/`}>
                            <button className="action">
                                <T id="newsletterBanner.newsletterSignUpButtonMobile"/>
                            </button>
                        </Link>
                    </p>
                </div>
            </div>
        );
    }

    return null;

};

export default NewsletterMobileBanner;
