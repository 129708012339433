import {useEffect, useState} from 'react';

const DesktopResponsive = () => {
    const [isDesktop, setIsDesktop] = useState(false);

    useEffect(() => {
        if (window.innerWidth > 1500) {
            setIsDesktop(true);
        } else {
            setIsDesktop(false);
        }

        const updateMedia = () => {
            if (window.innerWidth > 1500) {
                setIsDesktop(true);
            } else {
                setIsDesktop(false);
            }
        };
        window.addEventListener('resize', updateMedia);
        return () => window.removeEventListener('resize', updateMedia);
    }, []);

    return {
        isDesktop,
    };
};

export default DesktopResponsive;
